<template>
  <v-container
    fluid
    class="d-flex align-start pt-16 container--fluid background"
  >
    <v-row
      class="d-flex align-center"
    >
      <v-col
        cols="12"
        class="d-flex justify-center mt-16"
      >
        <v-card
          width="60vw"
        >
          <v-sheet
            width="90%"
            style="margin-right: 5%; margin-left: 5%"
            class="text-h3 text-center"
          >
            <div
              class="d-flex justify-center mt-12 mx-12"
            >
              <span class="d-flex font-weight-medium justify-center flex-wrap"> 404: Page not found!</span>
            </div>
            <div class="mx-12 mt-6 mb-12">
              <span class="d-flex justify-center flex-wrap"> Please double check that everything is spelled correctly in your URL, or click below to return to your Dashboard.</span>
            </div>
          </v-sheet>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-center"
      >
        <v-btn
          color="brown lighten-5"
          light
          @click="returnToDashboard"
        >
          Return To Dashboard
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'FourOhFour',
    data: () => ({
    }),
    methods: {
      returnToDashboard: function () {
        this.$router.push({ path: '/dashboard/' })
      },
    },
  }
</script>

<style>
.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 110vw;
  overflow: hidden;
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.75), rgba(51, 9, 1, 0.73)),
  url('../assets/register.jpg');
  background-size: cover;
}
</style>
